import gql from "graphql-tag";
import apolloClient from "@/apolloClient.js";

export const updateUserProfilePassword = async (idUserProfile, newPassword) =>
  await apolloClient.mutate({
    mutation: gql`
      mutation { updateUser (input: {where: {id: "${idUserProfile}"} data: {password: "${newPassword}"}}) 
        {
          user{
            id
          }
        }
      }
    `,
  });

export const updateUserProfile = async (user) =>
  await apolloClient.mutate({
    mutation: gql`
    mutation { updateUser (input: {where: {id: "${user.id}"} data: {address: "${user.address}" nameSurname: "${user.nameSurname}"
    email: "${user.email}" phone: "${user.phone}"}}) 
      {
        user{
          id
        }
      }
    }
  `,
  });

export const login = async (email, password) =>
  await apolloClient.mutate({
    mutation: gql`
      mutation {
        login(input: { identifier: "${email}", password: "${password}" }) {
          jwt
        }
      }
  `,
  });
