var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-xl mx-auto"},[_c('div',{staticClass:"mx-4"},[_c('h2',{staticClass:"text-3xl text-center text-body-4 my-12 font-medium"},[_vm._v(" "+_vm._s(_vm.formType === "reset" ? "Obnova hesla" : "Změna hesla")+" ")]),_c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-15 text-body-4 mt-12 font-bold"},[_vm._v("Nové heslo*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userProfile.updatePassword),expression:"userProfile.updatePassword"}],staticClass:"w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",class:(!_vm.$v.userProfile.updatePassword.required && _vm.submitted) ||
          (!_vm.$v.userProfile.updatePassword.minLength && _vm.submitted)
            ? 'border-red-300'
            : '',attrs:{"type":"password"},domProps:{"value":(_vm.userProfile.updatePassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userProfile, "updatePassword", $event.target.value)}}}),(!_vm.$v.userProfile.updatePassword.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_c('p',[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))])]):_vm._e(),(!_vm.$v.userProfile.updatePassword.minLength && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_c('p',[_vm._v(_vm._s(_vm.$t("ErrorMessages.PasswordMinLength")))])]):_vm._e()]),_c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-15 text-body-4 mt-12 font-bold"},[_vm._v("Zopakovat nové heslo*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userProfile.updatePassword2),expression:"userProfile.updatePassword2"}],staticClass:"w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",class:(!_vm.$v.userProfile.updatePassword2.sameAsPassword && _vm.submitted) ||
          (!_vm.$v.userProfile.updatePassword2.required && _vm.submitted) ||
          (!_vm.$v.userProfile.updatePassword2.minLength && _vm.submitted)
            ? 'border-red-300'
            : '',attrs:{"type":"password"},domProps:{"value":(_vm.userProfile.updatePassword2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userProfile, "updatePassword2", $event.target.value)}}}),(!_vm.$v.userProfile.updatePassword2.sameAsPassword && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_c('p',[_vm._v(_vm._s(_vm.$t("ErrorMessages.PasswordsAreNotSame")))])]):_vm._e(),(!_vm.$v.userProfile.updatePassword2.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_c('p',[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))])]):_vm._e(),(!_vm.$v.userProfile.updatePassword2.minLength && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_c('p',[_vm._v(_vm._s(_vm.$t("ErrorMessages.PasswordMinLength")))])]):_vm._e()]),_c('div',{staticClass:"flex justify-center"},[_c('button',{staticClass:"mt-12 text-15 text-white font-bold px-8 py-4 rounded-md bg-body-3 hover:opacity-80 duration-300 focus:outline-none",on:{"click":function($event){_vm.formType === 'change' ? _vm.save() : _vm.resetForgotPassword()}}},[_vm._v(" Uložit ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }