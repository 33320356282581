<template>
  <div class="max-w-xl mx-auto">
    <div class="mx-4">
      <h2 class="text-3xl text-center text-body-4 my-12 font-medium">
        {{ formType === "reset" ? "Obnova hesla" : "Změna hesla" }}
      </h2>
      <!-- <div>
        <label class="text-15 text-body-4 mt-12 font-bold"
          >Aktuální heslo*</label
        >
        <input
          v-model="userProfile.password"
          :class="
            !$v.userProfile.password.required && submitted
              ? 'border-red-300'
              : ''
          "
          type="password"
          class="w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5"
        />
        <label
          v-if="!$v.userProfile.password.required && submitted"
          class="font-medium text-sm text-red-500"
        >
          <p>{{ $t("ErrorMessages.FieldIsRequired") }}</p>
        </label>
      </div> -->
      <div class="mt-10">
        <label class="text-15 text-body-4 mt-12 font-bold">Nové heslo*</label>
        <input
          v-model="userProfile.updatePassword"
          :class="
            (!$v.userProfile.updatePassword.required && submitted) ||
            (!$v.userProfile.updatePassword.minLength && submitted)
              ? 'border-red-300'
              : ''
          "
          type="password"
          class="w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5"
        />
        <label
          v-if="!$v.userProfile.updatePassword.required && submitted"
          class="font-medium text-sm text-red-500"
        >
          <p>{{ $t("ErrorMessages.FieldIsRequired") }}</p>
        </label>
        <label
          v-if="!$v.userProfile.updatePassword.minLength && submitted"
          class="font-medium text-sm text-red-500"
        >
          <p>{{ $t("ErrorMessages.PasswordMinLength") }}</p>
        </label>
      </div>
      <div class="mt-10">
        <label class="text-15 text-body-4 mt-12 font-bold"
          >Zopakovat nové heslo*</label
        >
        <input
          v-model="userProfile.updatePassword2"
          :class="
            (!$v.userProfile.updatePassword2.sameAsPassword && submitted) ||
            (!$v.userProfile.updatePassword2.required && submitted) ||
            (!$v.userProfile.updatePassword2.minLength && submitted)
              ? 'border-red-300'
              : ''
          "
          type="password"
          class="w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5"
        />
        <label
          v-if="!$v.userProfile.updatePassword2.sameAsPassword && submitted"
          class="font-medium text-sm text-red-500"
        >
          <p>{{ $t("ErrorMessages.PasswordsAreNotSame") }}</p>
        </label>
        <label
          v-if="!$v.userProfile.updatePassword2.required && submitted"
          class="font-medium text-sm text-red-500"
        >
          <p>{{ $t("ErrorMessages.FieldIsRequired") }}</p>
        </label>
        <label
          v-if="!$v.userProfile.updatePassword2.minLength && submitted"
          class="font-medium text-sm text-red-500"
        >
          <p>{{ $t("ErrorMessages.PasswordMinLength") }}</p>
        </label>
      </div>
      <div class="flex justify-center">
        <button
          @click="formType === 'change' ? save() : resetForgotPassword()"
          class="mt-12 text-15 text-white font-bold px-8 py-4 rounded-md bg-body-3 hover:opacity-80 duration-300 focus:outline-none"
        >
          Uložit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//TODO: ### Zabezpečit ###
import { mapState, mapActions } from "vuex";
import { LOGOUT } from "@/store/user/constants";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { updateUserProfilePassword } from "@/graphql/mutations/userProfileMutations.js";
import axios from "axios";

export default {
  data() {
    return {
      submitted: false,
      userProfile: {
        password: "",
        updatePassword: "",
        updatePassword2: "",
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    formType() {
      const name = this.$route.name;
      if (name === "obnoveni-hesla") {
        return "reset";
      } else if (name === "nastaveni-zmena-hesla") {
        return "change";
      }
      return "";
    },
  },
  methods: {
    ...mapActions("user", {
      logoutStore: LOGOUT,
    }),
    async save() {
      try {
        this.submitted = true;
        this.$v.$touch();

        // const result = await getUserProfilePassword(
        //   this.user,
        //   this.userProfile
        // );

        // const validPassword = result.data.me.id;

        if (this.$v.$invalid) {
          return this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
            className: ["et-alert"],
          });
        } else {
          await updateUserProfilePassword(
            this.user.id,
            this.userProfile.updatePassword
          );
          await this.logoutStore();
          this.$router.push("prihlaseni");
        }
      } catch (error) {
        return this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert"],
        });
      }
    },
    async resetForgotPassword() {
      axios
        .post(`${process.env.VUE_APP_STRAPI_API_URL}/auth/obnoveni-hesla`, {
          code: this.$route.query.code,
          password: this.userProfile.updatePassword,
          passwordConfirmation: this.userProfile.updatePassword,
        })
        .then((response) => {
          this.$router.replace({ name: "prihlaseni" });
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
  },
  validations: {
    userProfile: {
      updatePassword: { required, minLength: minLength(6) },
      updatePassword2: {
        required,
        sameAsPassword: sameAs("updatePassword"),
        minLength: minLength(6),
      },
    },
  },
};
</script>
